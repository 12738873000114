.slider-item{
  display: block;
  position: relative;
  color:white;
  background-color: black;
  align-items: center;
  height: 100vh;
}

.slider-content{
  display: block;
  position: relative;
  height: 100%;
}

.slider-image{
  width: 100%;
  height: 50vh;
  color: #ffffff;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right center;
}

.slider-title{
  margin-top: -100px;
  z-index: 10;
  background: rgb(0,0,0);
  min-height: 100px;
  background: linear-gradient(180deg, rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.5) 15%, rgba(0,0,0,0.90) 50%, rgba(0,0,0,1) 100%);
}

@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Plus Jakarta Sans', sans-serif;
}

html {
    font-family: var(--primary-font);
    color: var(--dark-color);
    scroll-behavior: smooth;
    overflow-x: hidden;
}

li {
    list-style: none;
}

a {
    text-decoration: none;
    color: var(--primary-color)
}

body {
    overflow-x: hidden;
    background-color: #fff;
}


:root {
    --primary-font: 'Plus Jakarta Sans', sans-serif;
    --secondary-font: 'Open Sans', sans-serif;
    --dark-color: #000000;
    --primary-color: #791DB7;
    --info-color: #23907d;
    --success-color: #189d0e;
    --warning-color: #123123;
    --danger-color: #e20000;
    --secondary-color: #707070;
    --light-color: #bbb;
    --white-color: #fff;
    --general-transition: all .4s ease;
}

.camera-container {
    cursor: auto;
    display: block;
    position: relative;
    background-image: url("../../assets/next_background.jpg");
    background-color: #000;
    background-position: center;
    image-orientation: from-image;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    min-height: 100vh;

}
